import axios from 'axios';
const URL ='https://api.keemti.in';
const api_key = process.env.REACT_APP_EMP_API_KEY

export const adminloginapi = async (adminData) => {
    try{
        let response = await axios.post(`${URL}/admin/loginAdmin`,adminData,{
            headers:{
                'authorization':`EMP_API_KEY ${api_key}`
            }
        });
        return response;    
    }catch(e){
        console.log('error while signing up admin',e);
        return e;
    }
}